<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.dimType"
              placeholder="角色"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict['dimType']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 100px">
          <a-select
              class="input"
              v-model="queryParams.status"
              placeholder="状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict['validStatus']" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.dimName"
              placeholder="分类名称/描述"
              allowClear
          />
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
      <a-button v-hasPermission="'dim:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">
        新增
      </a-button>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('validStatus', 'color')" style="cursor: pointer">
          {{ record.status| dictName("validStatus") }}
        </a-tag>
      </template>
      <template slot="dimType" slot-scope="text, record">
        <a-tag :color="record.dimType | dictName('dimType', 'color')" style="cursor: pointer">
          {{ record.dimType| dictName("dimType") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" icon="edit" size="small" v-has-permission="'dim:edit'" @click="modify(record)">
          修改
        </a-button>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyDimensionModal";

export default {
  name: "DimensionList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "dimension/list",
      listMethod: "get",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "分类角色",
          align: "center",
          width: 80,
          dataIndex: "dimType",
          scopedSlots: {customRender: "dimType"},
        },

        {
          title: "维度名称",
          align: "center",
          width: 120,
          dataIndex: "dimName",
        },

        {
          title: "状态",
          width: 80,
          dataIndex: "status",
          align: "center",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "字段名称",
          width: 100,
          align: "center",
          dataIndex: "dimField",
        },
        {
          title: "维度说明",
          width: 400,
          dataIndex: "dimDesc",
          align: "center",
        },
        {
          title: "排序",
          width: 80,
          align: "center",
          dataIndex: "sortNumber",
        },
        {
          title: "操作",
          width: 100,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    add() {
      this.$refs.modifyModal.showModal(undefined, 'new');
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
